<template>
  <div>
    <iframe :src="publicPath + 'animate/jisussc/index.html'" scrolling="no" class="iframe" frameborder="0" ref="iframe" :height="iframeHeight"></iframe>
  </div>
</template>

<script>

import moment from 'moment'

// 开奖延时 (秒)
// const kOpenDelay = 60
// const kOpenDelay = 10
// 倒计时延迟
const kCountdownDelay = 3
// 动画播放时间
const kAnimateSec = 3
// 开奖结果显示时间
// const kResultPageDelay = 15
// 请求间隔
const kRequestInterval = 2

export default {
  name: 'jisussc',
  props: {
    code: String,
    id: String
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      iframeHeight: 870,
      midGame: false,
      timer: null,
      currentOpenCode: null
    }
  },
  mounted () {
    this.$refs.iframe.onload = () => {
      if (this.id === '16') {
        this.getIframeContentWindow(window => {
          window.$('.nameLogo.nameLogo_cq').css('background-image', 'url("./img/cqssc/name_dgwx.png")')
        })
      }
      this.iframeSizeFit()
      this.getState()
      // this.test()
    }
  },
  beforeDestroy () {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  },
  methods: {
    iframeSizeFit () {
      this.getIframeContentWindow(window => {
        const originWidth = 1334
        const originHeight = 870
        const currentWidth = this.$refs.iframe.offsetWidth
        const zoom = currentWidth / originWidth

        const html = window.document.documentElement
        html.style.zoom = zoom

        this.iframeHeight = originHeight * zoom

        window.ifopen = true
      })
    },
    test () {
      this.getIframeContentWindow(window => {
        window.pcEgg.startRoate()
      })
    },
    getState () {
      this.$api.live(this.code, this.id).then(({ data }) => {
        if (this.currentOpenCode !== data.opencode) {
          this.setupData(data)
        } else {
          var timer = setTimeout(() => {
            this.getState()
            clearTimeout(timer)
          }, kRequestInterval * 1000)
        }
      }).catch(err => {
        this.$message.error(err)
        var timer = setTimeout(() => {
          this.getState()
          clearTimeout(timer)
        }, 3000)
      })
    },
    setupData (data) {
      this.currentOpenCode = data.opencode

      const currentTime = new Date().getTime()
      // const nextTime = (data.next_open_time + kOpenDelay) * 1000
      const nextTime = data.next_open_time * 1000

      var counttime = parseInt((nextTime - currentTime) / 1000)
      if (counttime <= 0) {
        counttime = kCountdownDelay
      }
      const drawTime = moment(nextTime).format('HH:mm')
      const id = '#numBig'
      const preDrawCode = data.opencode_arr.map(item => parseInt(item))
      const preDrawIssue = data.expect

      this.getIframeContentWindow(window => {
        window.sscAnimateEnd({ counttime, drawTime, id, preDrawCode, preDrawIssue })
        this.timer = setTimeout(() => {
          clearTimeout(this.timer)
          this.getState()
        }, (counttime + kAnimateSec) * 1000)
      })
    },
    getIframeContentWindow (handler) {
      handler(this.$refs.iframe.contentWindow)
    }
  }
}
</script>

<style lang="scss" scoped>
.iframe {
  width: 100%;
}
</style>
